import { formatPercent } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent implements OnInit {

  debtCapital;
  ebitInterest;
  FCFE;
  FCFEGrowth;
  FCFEpershare;
  FCFEpershareGrowth;
  FCFECapital;
  FCFERevenue;
  RevenuePerShare
  RevenuePerShareGrowth;
  Revenue = [];
  RevenueGrowth;
  adquisitionsNet;
  capitalExpenditure;
  cashAtBeginningOfPeriod;
  cashAtEndOfPeriod;
  
  debtRepayment;
  dividendAndSharesRepurchases;
  netCashProvidedByOperatingActivities;
  netInvestments;
  valuation = {
    baz: 42,
    Sub: 23
};
basicData = {
  name: 42,
  symbol: 23
};
  closed = false;

  searchtext = "Enter Symbol";
  public searchField: FormControl;
  isOK = true;
  index = 1;
  
  constructor(private api: ApiService,  @Inject(LOCALE_ID) public locale: string) { 
    this.searchField = new FormControl('');
    this.valuation["value"] = 0;
    this.api.analysis("AMRS"
      ).subscribe(data => {
        console.info(data);
       this.input(data);
       console.info(this.netInvestments[this.index]);
        
      });
  }

  changeAcordeon(){
    if (this.closed){
      this.closed = false;
    } else {
      this.closed = true;
    }
  }

  search(){
    
    
    this.api.analysis(this.searchField.value.toUpperCase()
      ).subscribe(data => {
        this.input(data);
        console.log(data);
        this.searchField.setValue(null);
      },
      err => {
        this.isOK = false;
      },
      );
    
  }

  searchNext(){
    
    
    this.api.analysis(this.basicData['next']
      ).subscribe(data => {
        this.input(data);
        console.log(data);
        this.searchField.setValue(null);
      },
      err => {
        this.isOK = false;
      },
      );
    
  }

  input(data){
    this.debtCapital = data['Debt/Capital'];
    this.FCFECapital = data['FCFE/Capital'];
    this.ebitInterest = data['EBIT/Interest'];
    this.FCFE = data['FCFE'];
    
    this.FCFEGrowth = data['FCFEGrowth'];
    this.FCFEpershare = data['FCFE per Share'];
    this.FCFEpershareGrowth = data['FCFE per Share Growth'];
    this.FCFERevenue = data['FCFE/Revenue'];
    this.RevenuePerShare = data['Revenue per Share']
    this.RevenuePerShareGrowth = data['Revenue per Share Growth'];
    this.Revenue = data['revenue'];
    this.RevenueGrowth = data['RevenueGrowth']; 
    this.adquisitionsNet = data['acquisitionsNet'];
    this.capitalExpenditure = data['capitalExpenditure'];
    this.cashAtBeginningOfPeriod = data['cashAtBeginningOfPeriod'];
    this.cashAtEndOfPeriod = data['cashAtEndOfPeriod'];
    this.basicData = data['data'];
    this.debtRepayment = data['debtRepayment'];
    this.dividendAndSharesRepurchases = data['dividendAndSharesRepurchases'];
    this.netCashProvidedByOperatingActivities = data['netCashProvidedByOperatingActivities'];
    this.netInvestments = data['netInvestments'];
    this.valuation = data['valuation'];
    this.searchtext = this.basicData.name + " | " + this.basicData.symbol;
    this.isOK = true;
  }


  ngOnInit() {    
  }

}
