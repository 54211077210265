import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {

  com;
  soc;
  con;
  constructor(private conn: ConnectionService,  @Inject(LOCALE_ID) public locale: string, private api:ApiService, private route: ActivatedRoute) { 
    
    this.api.loadToken().then( res => (
      this.pato()
    ));
   }

  pato(){
    let url = "/activity?com=" + this.com + "&soc=" + this.soc + "&con=" + this.con;
    this.conn.getAccounting(url).subscribe( async res2 => {
      console.log(res2);
    });
  }


  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.com = params.com;
        this.soc = params.soc;
        this.con = params.con;
        
      }
    );
  }

}
