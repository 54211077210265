import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SharedModule } from '../../shared/shared.module';
import { DatePipe, formatPercent, PercentPipe } from '@angular/common';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {

  sp500;
  nasdaq;
  adarga;
  percentage;
  constructor(private api: ApiService,  @Inject(LOCALE_ID) public locale: string) {
    this.api.results(
      
      ).subscribe(data => {
        console.info(data);
        this.sp500 = formatPercent(data['sp500Profit'], this.locale,
        '1.0-1');
        this.nasdaq = formatPercent(data['nasdaqProfit'], this.locale,
        '1.0-1');
        this.adarga = formatPercent(data['adarga'], this.locale,
        '1.0-1');
        this.percentage = data['percentage'].sort(function(a, b){return b.percentaje-a.percentaje});
      });
   }
  

  ngOnInit() {}

}
