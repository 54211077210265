import { Component, OnInit } from '@angular/core';
import { TransferService } from 'src/app/services/transfer.service';

@Component({
  selector: 'app-addcompany',
  templateUrl: './addcompany.component.html',
  styleUrls: ['./addcompany.component.scss'],
})
export class AddcompanyComponent implements OnInit {

  input2;
  
  constructor(private transfer: TransferService) { }

  addCompany(){
    console.log(this.input2);
    this.transfer.companies.unshift(this.input2);
    this.transfer.companyElected = this.transfer.companies.find(o => o === this.input2);
  }

  ngOnInit() {}

}
