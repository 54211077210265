import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransferService {


  /**
   *    Variables and functions for transfer between select controler to choose companies and input to create a new ticker
   */

   companyElected: any;
   companies: any[] = [];


  sections: any[];
  sectionSelected: string;

  


  constructor() { 
    console.log("TransferService");
  }
    
    setSections(data: any[]){
      this.sections = data;
  }

  getSections() {
    return this.sections;
  }

  setSectionSelected(data: string){
    this.sectionSelected = data;
  }

  getSectionSelected() {
    return this.sectionSelected;
  }


  
  




}
